/**
* @license
*
* Font Family: Clash Display
* Designed by: Indian Type Foundry
* URL: https://www.fontshare.com/fonts/clash-display
* © 2024 Indian Type Foundry

/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
@font-face {
  font-family: 'ClashDisplay-Variable';
  src: url('../fonts/ClashDisplay-Variable.woff2') format('woff2'),
       url('../fonts/ClashDisplay-Variable.woff') format('woff'),
       url('../fonts/ClashDisplay-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

